import FileSaver from "file-saver";
import moment from "moment";
import { useState, useEffect, ReactNode } from "react";
import ReactDatePicker from "react-datepicker";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  createSetByPeriodIdApi,
  deleteLotteryByIdApi,
  filterLotteryApi,
  getLotterySetSummaryByPeriodIdApi,
  getPeriodLotteryByIdApi,
  updateLotteryByIdApi,
} from "../../../api/lottery";
import Breadcrumb from "../../../component/breadcrumb";
import Pagination from "../../../component/pagination";
import {
  ILotterySetSummary,
  ILottery,
  IPeriodLottery,
  IUpdateLotteryReq,
} from "../../../interface/lottery";
import Meta from "../../../interface/meta";
import { setAppLoading } from "../../../redux/app.reducer";
import { useAppConfirm, useAppDispatch } from "../../../redux/hooks";
import useSnackbar from "../../../utils/snackbar";
import { getSrcImage } from "../../../utils/utils";
import EditLotteryModal from "./edit-lottery-modal";

const LotteryDetail = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { confirm } = useAppConfirm();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(false);
  const [periodLottery, setPeriodLottery] = useState<IPeriodLottery>();
  const [summary, setSummary] = useState<ILotterySetSummary>();
  const [lottery, setLottery] = useState<ILottery[]>([]);
  const [modalEdit, setModalEdit] = useState({
    open: false,
    data: {} as ILottery,
  });
  const [meta, setMeta] = useState<Meta>({
    page: 1,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [filter, setFilter] = useState({
    number: "",
    status: "",
    totalInset: 0,
    createBegin: 0,
    createEnd: 0,
  });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const initData = async () => {
    try {
      setLoading(true);
      const period = await getPeriodLotteryByIdApi(id!);
      setPeriodLottery(period.data);
      const [summary] = await Promise.all([
        getLotterySetSummaryByPeriodIdApi(id!),
      ]);
      setSummary(summary.data);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/lottery`);
    }
  };

  const filterLottery = async (id: string, page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterLotteryApi({
        periodId: id,
        number: filter.number,
        status: filter.status,
        totalInset: filter.totalInset !== 0 ? filter.totalInset : undefined,
        createBegin:
          filter.createBegin !== 0
            ? moment.unix(filter.createBegin).utc().unix()
            : 0,
        createEnd:
          filter.createEnd !== 0
            ? moment.unix(filter.createEnd).utc().unix()
            : 0,
        page,
        limit,
      });

      setLottery(data.results ?? []);
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const exportLotteryCsv = async (id: string) => {
    try {
      setLoading(true);
      const response = await filterLotteryApi({
        periodId: id,
        number: filter.number,
        status: filter.status,
        totalInset: filter.totalInset !== 0 ? filter.totalInset : undefined,
        createBegin:
          filter.createBegin !== 0
            ? moment.unix(filter.createBegin).utc().unix()
            : 0,
        createEnd:
          filter.createEnd !== 0
            ? moment.unix(filter.createEnd).utc().unix()
            : 0,
        type: "csv",
      });

      let BOM = "\uFEFF";
      let csvData = (BOM + response.data) as any;
      let blob = new Blob([csvData], {
        type: "text/csv;charset=utf-8",
      });

      FileSaver.saveAs(blob, `lottery.csv`);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const createSetByPeriodId = async (id: string) => {
    try {
      setLoading(true);
      await createSetByPeriodIdApi(id);
      const { data } = await filterLotteryApi({
        periodId: id,
        number: filter.number,
        status: filter.status,
        totalInset: filter.totalInset !== 0 ? filter.totalInset : undefined,
        createBegin:
          filter.createBegin !== 0
            ? moment.unix(filter.createBegin).utc().unix()
            : 0,
        createEnd:
          filter.createEnd !== 0
            ? moment.unix(filter.createEnd).utc().unix()
            : 0,
        page: meta.page,
        limit: meta.limit,
      });

      setLottery(data.results ?? []);
      setMeta((meta) => ({
        page: meta.page,
        limit: meta.limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      }));
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const updateLotteryById = async (
    lotteryId: string,
    payload: IUpdateLotteryReq
  ) => {
    try {
      setLoading(true);
      await updateLotteryByIdApi(lotteryId, payload);
      const [period, summary, lottery] = await Promise.all([
        getPeriodLotteryByIdApi(id!),
        getLotterySetSummaryByPeriodIdApi(id!),
        filterLotteryApi({
          number: filter.number,
          status: filter.status,
          periodId: id!,
          page: meta.page,
          limit: meta.limit,
        }),
      ]);
      setPeriodLottery(period.data);
      setSummary(summary.data);
      setLottery(lottery.data.results ?? []);
      setMeta({
        page: lottery.data.meta.page,
        limit: lottery.data.meta.limit,
        totalCount: lottery.data.meta.totalCount,
        hasMore: lottery.data.meta.hasMore,
      });
      setModalEdit({ open: false, data: {} as ILottery });
      setLoading(false);
      openSnackbar("แก้ไขสำเร็จ");
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const deleteLotteryById = async (lotteryId: string) => {
    try {
      const isConfirmed = await confirm({
        title: "ยืนยันการทำรายการ",
        description: "ยืนยันการลบข้อมูล",
        textCancel: "ยกเลิก",
        classBtnCancel: "btn btn-light",
        textConfirm: "ยืนยัน",
        classBtnConfirm: "btn btn-danger",
      });

      if (!isConfirmed) return;

      setLoading(true);
      await deleteLotteryByIdApi(lotteryId);
      const [period, summary, lottery] = await Promise.all([
        getPeriodLotteryByIdApi(id!),
        getLotterySetSummaryByPeriodIdApi(id!),
        filterLotteryApi({
          number: filter.number,
          status: filter.status,
          periodId: id!,
          page: meta.page,
          limit: meta.limit,
        }),
      ]);
      setPeriodLottery(period.data);
      setSummary(summary.data);
      setLottery(lottery.data.results ?? []);
      setMeta({
        page: lottery.data.meta.page,
        limit: lottery.data.meta.limit,
        totalCount: lottery.data.meta.totalCount,
        hasMore: lottery.data.meta.hasMore,
      });
      openSnackbar("ลบข้อมูลสำเร็จ");
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterLottery(id!, 1, meta.limit);
    // eslint-disable-next-line
  }, [filter]);

  let status: ReactNode;
  switch (periodLottery?.status) {
    case 0:
      status = <span className="badge badge-small badge-danger">ปิด</span>;
      break;
    case 1:
      status = <span className="badge badge-small badge-success">เปิด</span>;
      break;
    default:
      status = <span className="badge badge-small badge-light">-</span>;
  }

  return (
    <>
      <div className="dashboard-header d-flex flex-column grid-margin ">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <Breadcrumb
            title={`${periodLottery?.period.day ?? ""} ${
              !!periodLottery
                ? moment.months(periodLottery!.period.month - 1)
                : ""
            } ${!!periodLottery ? periodLottery!.period.year + 543 : ""}`}
            children={[
              { title: "หน้าแรก", link: "/" },
              { title: "งวดลอตเตอรี่", link: "/lottery" },
              {
                title: `${periodLottery?.period.day ?? ""} ${
                  !!periodLottery
                    ? moment.months(periodLottery!.period.month - 1)
                    : ""
                } ${!!periodLottery ? periodLottery!.period.year + 543 : ""}`,
              },
            ]}
          />
          <Link
            to={`/lottery/detail/${id}/add`}
            className="btn btn-primary ml-auto"
          >
            <span className="fa fa-plus mr-2"></span>
            เพิ่มลอตเตอรี่
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col-auto d-none d-md-flex">
                  <div className="btn btn-primary">
                    <h2 className="m-0">{periodLottery?.period.day ?? ""}</h2>
                    <h4 className="m-0">
                      {!!periodLottery
                        ? moment.monthsShort(periodLottery!.period.month - 1)
                        : ""}
                    </h4>
                  </div>
                </div>

                <div className="col-lg ">
                  <div>
                    {!!periodLottery
                      ? `${periodLottery!.period.day} ${moment.months(
                          periodLottery!.period.month - 1
                        )} ${periodLottery!.period.year + 543}`
                      : ""}
                  </div>
                  {status}
                  <br />
                </div>
                <div className="col  d-none d-md-flex">
                  <span className="text-muted tx-13 m-0 p-0">
                    {!!periodLottery
                      ? moment(periodLottery!.openDatetime).format(
                          "DD/MM/yyyy HH:mm:ss"
                        )
                      : ""}
                    <div className="text-center">-</div>
                    {!!periodLottery
                      ? moment(periodLottery!.closeDatetime).format(
                          "DD/MM/yyyy HH:mm:ss"
                        )
                      : ""}
                  </span>
                </div>

                <div className="col-md ">
                  <span className="tx-12 text-muted">
                    ลอตเตอรี่ในระบบทั้งหมด
                  </span>
                  <br />
                  <span className="h2 mr-2  text-primary">
                    {periodLottery?.totalLottery.toLocaleString("en-US") ?? 0}
                  </span>
                </div>

                <div className="col-md ">
                  <span className="tx-12 text-muted">ขายแล้ว</span>
                  <br />
                  <span className="h2 mr-2  text-success">
                    {summary?.sold.toLocaleString("en-US") ?? 0}
                  </span>
                </div>

                <div className="col-md ">
                  <span className="tx-12 text-muted">ติดจอง</span>
                  <br />
                  <span className="h2 mr-2 text-warning">
                    {summary?.reserved.toLocaleString("en-US") ?? 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-auto">
                  <span className="mr-2 ">ทั้งหมด</span>
                  <span className="h2 mr-2 ">
                    {periodLottery?.totalLottery.toLocaleString("en-US") ?? 0}
                  </span>
                  <span>ใบ</span>
                </div>

                <form className="col-lg p-0">
                  <div className="row">
                    <div className="col-lg-2 ml-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="ค้นหาลอตเตอรี่"
                        value={filter.number}
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            number: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-lg-2">
                      <div className="form-group">
                        <select
                          className="form-control"
                          value={filter.totalInset ?? "0"}
                          onChange={(e) => {
                            setFilter((prev) => ({
                              ...prev,
                              totalInset: +e.target.value,
                            }));
                          }}
                        >
                          <option value="0">แสดงทั้งหมด</option>
                          {summary?.sets
                            .sort((i, j) => i.set - j.set)
                            .map((e) => (
                              <option key={"otp_" + e.set} value={e.set}>
                                {e.set}ใบ
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="form-group">
                        <select
                          className="form-control"
                          value={filter.status}
                          onChange={(e) => {
                            setFilter((prev) => ({
                              ...prev,
                              status: e.target.value,
                            }));
                          }}
                        >
                          <option value="">แสดงทั้งหมด</option>
                          <option value="0">รอจัดชุด</option>
                          <option value="1">พร้อมขาย</option>
                          <option value="2">กำลังจอง</option>
                          <option value="3">ขายแล้ว</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <ReactDatePicker
                        selected={startDate}
                        onChange={(dates: [Date | null, Date | null]) => {
                          const [start, end] = dates;
                          setStartDate(start);
                          setEndDate(end);
                          if ((!!start && !!end) || (!start && !end)) {
                            setFilter((prev) => ({
                              ...prev,
                              createBegin: (start?.valueOf() ?? 0) / 1000,
                              createEnd: (end?.valueOf() ?? 0) / 1000,
                            }));
                          }
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        dateFormat={"dd/MM/yyyy"}
                        placeholderText="วันที่ จาก - ถึง"
                        isClearable={true}
                        customInput={<input className="form-control" />}
                      />
                    </div>
                    <div className="col-auto">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          createSetByPeriodId(id!);
                        }}
                      >
                        <span className="fa fa-star mr-2"></span>จัดชุด
                      </button>
                    </div>
                    <div className="col-auto">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => {
                          exportLotteryCsv(id!);
                        }}
                      >
                        <span className="fa fa-download mr-2"></span>Export
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="row ">
                <div className="col-12 border-top p-2">
                  {summary?.sets
                    .sort((i, j) => i.set - j.set)
                    .map((e) => (
                      <span
                        key={e.set}
                        className="badge badge-outline-primary badge-pill m-1"
                      >
                        ชุด {e.set} ใบ {e.total.toLocaleString("en-US")}ชุด
                      </span>
                    ))}
                </div>
              </div>

              <div className="row">
                <div className="table-responsive">
                  <table id="dataTable" className="table">
                    <thead>
                      <tr>
                        <th style={{ width: 250 }}>รูป</th>
                        <th>ปี-งวด-ชุดที่</th>
                        <th className="text-center">เลข</th>
                        <th>จัดชุด</th>
                        <th>หมายเลขกล่อง</th>
                        <th>วันที่นำเข้า</th>
                        <th>สถานะ</th>
                        <th style={{ width: 200 }} className="text-right">
                          ดำเนินการ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {lottery.length > 0 ? (
                        lottery.map((e) => {
                          let status: ReactNode;
                          switch (e.status) {
                            case 0:
                              status = (
                                <div className="badge badge-info tx-11">
                                  รอจัดชุด
                                </div>
                              );
                              break;
                            case 1:
                              status = (
                                <div className="badge badge-primary tx-11">
                                  พร้อมขาย
                                </div>
                              );
                              break;
                            case 2:
                              status = (
                                <div className="badge badge-warning tx-11">
                                  ติดจอง
                                </div>
                              );
                              break;
                            case 3:
                              status = (
                                <div className="badge badge-success tx-11">
                                  ขายแล้ว
                                </div>
                              );
                              break;
                            default:
                              status = (
                                <div className="badge badge-light tx-11">-</div>
                              );
                          }
                          return (
                            <tr key={e.id}>
                              <td>
                                <div className="thumbnail_lotto">
                                  <img
                                    className="lazy img-responsive"
                                    alt=""
                                    src={getSrcImage(e.picture, "?tr=w-250")}
                                  />
                                </div>
                              </td>
                              <td>{e.yearPeriodSet}</td>
                              <td>
                                <h3 className=" text-center">{e.number}</h3>
                              </td>
                              <td>{e.totalInSet} ใบ</td>
                              <td>{`กล่องหมายเลข ${e.boxId}`}</td>
                              <td>
                                {moment(e.createdAt).format("yyyy/MM/DD")}
                                <br />
                                <span className="text-muted mb-0 tx-11">
                                  <span className="fa fa-user mr-2"></span>
                                  By {e.createdUser.firstName}{" "}
                                  {e.createdUser.lastName}
                                </span>
                              </td>
                              <td>{status}</td>
                              <td className="text-right">
                                {[0, 1].includes(e.status) && (
                                  <>
                                    <button
                                      onClick={() => {
                                        setModalEdit({ open: true, data: e });
                                      }}
                                      className="btn btn-outline-primary mr-1 mb-1 pt-2"
                                    >
                                      <span className="fa fa-edit"></span>
                                    </button>
                                    <button
                                      onClick={() => {
                                        deleteLotteryById(e.id);
                                      }}
                                      className="btn btn-outline-primary  mb-1 pt-2"
                                    >
                                      <span className="fa fa-trash-o"></span>
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className="text-center">No data</div>
                          </td>
                          f
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <Pagination
            active={meta.page}
            limit={meta.limit}
            onPageChange={(page) => {
              filterLottery(id!, page, meta.limit);
            }}
            pageRangeDisplayed={5}
            total={meta.totalCount}
          />

          <EditLotteryModal
            open={modalEdit.open}
            data={modalEdit.data}
            toggle={() => {
              setModalEdit({ open: false, data: {} as ILottery });
            }}
            onSubmit={updateLotteryById}
          />
        </div>
      </div>
    </>
  );
};

export default LotteryDetail;

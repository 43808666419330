import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken as FGetToken,
  MessagePayload,
  onMessage,
} from "firebase/messaging";
import debouce from "lodash/debounce";

const firebaseConfig = {
  apiKey: "AIzaSyBEufthBbqOEPnYba59UWf28UgDGKHKXKE",
  authDomain: "whitetiger-lottery.firebaseapp.com",
  projectId: "whitetiger-lottery",
  storageBucket: "whitetiger-lottery.appspot.com",
  messagingSenderId: "838079922870",
  appId: "1:838079922870:web:e7f9fe1872072005609c17",
  measurementId: "G-ZP3BRXZTQT",
};

const msgKey =
  "BE1lboZNfBDcpmlH6Hi5Mx2rk4RUFvMyQxYDV9zRupPleIboBiIbvPM7dlbQNtYz3zoskH2JWMk-x0GD-i8ch4M";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getToken = async (): Promise<string | null> => {
  try {
    let currentToken = await FGetToken(messaging, { vapidKey: msgKey });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      return currentToken;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return null;
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
    throw error;
  }
};

export const onMessageListener = (
  cb: (data: MessagePayload) => void
): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      cb(payload);
      debouncedPlaySound();
      resolve(payload);
    });
  });

function playSound() {
  var audio = new Audio("/audio/new-order.mp3");
  audio.play();
}

// Usage:
// This will create a debounced version of the playSound function
// that, when called repeatedly, will only actually invoke playSound
// at most once every 1 second.
var debouncedPlaySound = debouce(() => {
  playSound();
}, 1000);
